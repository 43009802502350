import React from "react";
import "./styles.css";
import Nav from "./navbar";
import Row from "./row";
import * as data from "./data"; //database


export default function App() {
  return (
    <div className="App">
      {Nav()}
      <h2
        className="display-2"
        style={{
          fontSize: "32px",
          textAlign: "left",
          color: "#f3b00c",
          marginTop: "5px",
          paddingLeft: "15px",
          paddingRight: "25px"
        }}
      >
        Day 19 - 21
      </h2>
      {Row(1, data.data[0], data.data[1], data.data[2])}
      {Row(2, data.data[3], data.data[4], data.data[5])}
      {Row(3, data.data[6], data.data[7], data.data[8])}
      {Row(4, data.data[9], data.data[10], data.data[11])}
      {Row(5, data.data[12], data.data[13], data.data[14])}
      {Row(6, data.data[15], data.data[16], data.data[17])}
      {Row(7, data.data[18], data.data[19], data.data[20])}
      {Row(8, data.data[21], data.data[22], data.data[23])}
      {Row(9, data.data[24], data.data[25], data.data[26])}
      {Row(10, data.data[27], data.data[28], data.data[29])}
      {Row(11, data.data[30], data.data[31], data.data[32])}
      {Row(12, data.data[33], data.data[34], data.data[35])}
      {Row(13, data.data[36], data.data[37], data.data[38])}
      {Row(14, data.data[39], data.data[40], data.data[41])}
      {Row(15, data.data[42], data.data[43], data.data[44])}
      {Row(16, data.data[45], data.data[46], data.data[47])}
      {Row(17, data.data[48], data.data[49], data.data[50])}
      {Row(18, data.data[51], data.data[52], data.data[53])}
      {Row(19, data.data[54], data.data[55], data.data[56])}
      {Row(20, data.data[57], data.data[58], data.data[59])}
      {Row(21, data.data[60], data.data[61], data.data[62])}
      {Row(22, data.data[63], data.data[64], data.data[65])}
      {/* {Row(23, data.data[66], data.data[67], data.data[68])} */}
      {/* {Row(24, data.data[69], data.data[70], data.data[71])} */}
      {/* {Row(25, data.data[72], data.data[73], data.data[74])} */}
      {/* {Row(26, data.data[75], data.data[76], data.data[77])} */}
      {/* {Row(27, data.data[78], data.data[79], data.data[80])} */}
      {/* {Row(28, data.data[81], data.data[82], data.data[83])} */}
      {/* {Row(29, data.data[84], data.data[85], data.data[86])}
      {Row(30, data.data[87], data.data[88], data.data[89])}
      {Row(31, data.data[90], data.data[91], data.data[92])} */}
    </div>
  );
}
